import React from 'react';
import { Card, Image, Title } from "rbx";
import foto11 from '../assets/images/foto11.png'
import horizontalLine from '../assets/images/horizontal-line.png'

export default function PhoneButton({size="small"}) {
  if (size==="small") {
    return (
      <a 
        className="button is-rounded is-fullwidth has-background-custom-royal-darker has-text-white"
        href="#home"
      >
        <Image.Container size={24} className="mr-2">
          <Image src={foto11} alt="foto11" style={{filter: 'invert(100%)'}} />
        </Image.Container>
        <strong>LIGAMOS PARA VOCÊ!</strong>
      </a>
    )
  } else if (size==="large") {
    return (
      <Card>
        <Card.Content className="pt-6 pb-0">
          <a href="#home">
            <Image.Container size={96} className="mx-auto mb-6">
              <Image src={foto11} alt="foto11"/>
            </Image.Container>
            <Title subtitle size={4} className="has-text-weight-medium">
              Fale Conosco
            </Title>
            <Title className="mb-0">
              (85) 3221-2422
            </Title>
            <Image.Container size={48} className="mx-auto">
              <Image src={horizontalLine} alt="horizontalLine" />
            </Image.Container>
          </a>
        </Card.Content>
      </Card>
    )
  } else {
    return (<span>size invalid!</span>)
  }
}