import React, { useEffect } from "react";
import { Container, Navbar } from "rbx";
import "../styles/temp.scss";
import logoTemp from '../assets/images/logo_temp.png'
import logoTempWhite from '../assets/images/logo_temp_white.png'

function scrollFunction() {
  let top = (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) ? "0" : "-4.5rem";
  document.getElementById("top-menu-float").style.top = top;
}

export default function Header() {
  useEffect(()=>{
    window.onscroll = function() { scrollFunction() };
  },[]);

  return (
    <>
      <Navbar id="top-menu" className="has-shadow" color="custom-royal" >
        <Container>
          <Navbar.Brand className="temp-navbar">
            <img src={logoTempWhite} alt="" width="135" />
            <Navbar.Burger />
          </Navbar.Brand>
          <Navbar.Menu >
            <Navbar.Segment align="end">
              <Navbar.Item className='mr-6' href="#home">Home</Navbar.Item>
              <Navbar.Item className='mr-6' href="#nossas-solucoes">Funcionalidades</Navbar.Item>
              <Navbar.Item className='mr-6' href="#nossos-planos">Planos</Navbar.Item>
              <Navbar.Item className='mr-6' href="#clientes-satisfeitos">Caso de Sucesso</Navbar.Item>
              <Navbar.Item className='mr-6' href="#nossa-historia">Sobre nós</Navbar.Item>
              <Navbar.Item href="#atendimento">Atendimento</Navbar.Item>
            </Navbar.Segment>
          </Navbar.Menu>
        </Container>
      </Navbar>

      <Navbar id="top-menu-float" className="is-sticky has-shadow" fixed="top">
        <Container>
          <Navbar.Brand className="">
            <img src={logoTemp} alt="" width="135" />
          </Navbar.Brand>
          <Navbar.Menu >
            <Navbar.Segment align="end">
              <Navbar.Item className='mr-6' href="#home">Home</Navbar.Item>
              <Navbar.Item className='mr-6' href="#nossas-solucoes">Funcionalidades</Navbar.Item>
              <Navbar.Item className='mr-6' href="#nossos-planos">Planos</Navbar.Item>
              <Navbar.Item className='mr-6' href="#clientes-satisfeitos">Caso de Sucesso</Navbar.Item>
              <Navbar.Item className='mr-6' href="#nossa-historia">Sobre nós</Navbar.Item>
              <Navbar.Item href="#atendimento">Atendimento</Navbar.Item>
            </Navbar.Segment>
          </Navbar.Menu>
        </Container>
      </Navbar>
    </>
  )

}