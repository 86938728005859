import React from "react";
import "./App.scss";
import "./styles/temp.scss";
import { Box, Button, Card, Column, Container, Content, Control, Field, Footer, Hero, Image, Input, Label, Media, Section, Tag, Title } from "rbx";
import WhatsappButton from "./components/WhatsappButton";
import Header from "./components/Header"
import foto1 from './assets/images/foto1-2.png'
import foto2 from './assets/images/foto2.png'
import foto3 from './assets/images/foto3.png'
import foto4 from './assets/images/foto4.png'
import foto5 from './assets/images/foto5.png'
import foto6 from './assets/images/foto6.png'
import foto7 from './assets/images/foto7.png'
import foto8 from './assets/images/foto8.png'
import foto9 from './assets/images/foto9.png'
import foto10 from './assets/images/foto10.png'
import logoTempWhite from './assets/images/logo_temp_white.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import PhoneButton from "./components/PhoneButton";

const App = () => {

  return (
    <>
      <Header />

      <Hero id="home" className="home" color="" size="fullheight-with-navbar">
        <Column.Group gapless>
          <Column size={5}>
            <Column.Group centered>
              <Column size={8}>
                <Section className="py-5 px-0">
                  <Title subtitle size={2} className="has-text-custom-royal-darker has-text-weight-medium is-size-3-mobile">
                    A Temp Soluções tem o sistema ideal para gerenciamento de orgãos de trânsito!
                  </Title>
                  <Title subtitle size={4} className="has-text-custom-royal-darker is-size-5-mobile">
                    Tenha todo os controles essenciais do seu negócio em um único lugar, seja via web ou mobile.
                  </Title>
                  <Box className="has-background-custom-royal-darker">
                    <form>
                      <Title size={5} className="has-text-white has-text-centered mb-1">Ligamos para você!</Title>
                      <Field className="mb-1">
                        <Label className="has-text-white mb-0" size="">Nome</Label>
                        <Control>
                          <Input name="name" placeholder="Digite seu nome" size="" />
                        </Control>
                      </Field>
                      <Field className="mb-1">
                        <Label className="has-text-white mb-0" size="">Email</Label>
                        <Control>
                          <Input name="email" placeholder="Digite seu email" size="" />
                        </Control>
                      </Field>
                      <Field className="mb-1">
                        <Label className="has-text-white mb-0" size="">Telefone</Label>
                        <Control>
                          <Input name="phone" placeholder="Digite seu telefone" size="" />
                        </Control>
                      </Field>
                      <Field className="mb-1">
                        <Control>
                          <Column.Group breakpoint="mobile">
                            <Column textAlign="right">
                              <Button type="button" color="primary" >
                                <FontAwesomeIcon icon={faTelegramPlane} className="mr-2" />
                                <strong>Enviar</strong>
                              </Button>
                            </Column>
                          </Column.Group>
                        </Control>
                      </Field>
                    </form>
                  </Box>
                </Section>
              </Column>
            </Column.Group>
          </Column>
          <Column size={7}>
            <Image.Container>
              {/* <Image src={foto1} alt="foto1" style={{borderBottomLeftRadius:'25%', clipPath:'polygon(100% 0%, 100% 80.69%, 0% 106%, 0% 0%)'}} /> */}
              <Image src={foto1} alt="foto1" style={{borderBottomLeftRadius:'25%'}} />
  {/*
              <div 
                className="has-background-custom-red" 
                style={{height:'5rem', clipPath: 'polygon(100% 0%, 100% 100%, 50% 100%, 100% 0%)'}}
              >
                <Button type="button" color="custom-green" rounded fullwidth>
                  <Image.Container size={24} className="mr-2">
                    <Image src={foto12} alt="foto12" />
                  </Image.Container>
                  <strong>FALAR COM CONSULTOR</strong>
                </Button>
              </div>
  */}
            </Image.Container>
          </Column>
        </Column.Group>
        <Column.Group>
          <Column>
          </Column>
          <Column>
            
          </Column>
        </Column.Group>
      </Hero>

      <Section id="nossas-solucoes" className="nossas-solucoes">
        <Container>
          <Title className="has-text-custom-royal has-text-centered">
            VEJA NOSSAS <span className="has-text-custom-red">SOLUÇÕES</span>
          </Title>
          <Title size={4} className="has-text-centered has-text-custom-royal-darker">
            Confira abaixo nossas soluções empresariais que farão seu gerenciamento mudar de patamar
          </Title>

          <Column.Group style={{justifyContent:'space-around'}}>
            <Column size="5">
              <Box>
                <Media>
                  <Media.Item align="left">
                    <Image.Container size={64}>
                      <Image src={foto3} alt="foto3" />
                    </Image.Container>
                  </Media.Item>
                  <Media.Item>
                    <Content>
                      <p className="has-text-custom-royal-darker">
                        <Title size={4} className="has-text-custom-royal-darker">Gestão de Pátios</Title>
                        Leilão de veículos removidos a depósito, quer realizar e não sabe como? Então venha 
                        conversar conosco, temos a consultoria e o sistema certo para o seu órgão de trânsito. 
                        Sistema dentro das normas e regras da Resolução n. 623, de 06/09/16, com integração junto 
                        ao DETRAN-CE. 
                      </p>
                    </Content>
                  </Media.Item>
                </Media>
              </Box>
            </Column>
            <Column size="5">
              <Box>
                <Media>
                  <Media.Item align="left">
                    <Image.Container size={64}>
                      <Image src={foto3} alt="foto3" />
                    </Image.Container>
                  </Media.Item>
                  <Media.Item>
                    <Content>
                      <p className="has-text-custom-royal-darker">
                        <Title size={4} className="has-text-custom-royal-darker">Regularizar veículos leiloados</Title>
                        Leilão de veículos removidos a depósito, quer realizar e não sabe como? Então venha 
                        conversar conosco, temos a consultoria e o sistema certo para o seu órgão de trânsito. 
                        Sistema dentro das normas e regras da Resolução n. 623, de 06/09/16, com integração junto 
                        ao DETRAN-CE. 
                      </p>
                    </Content>
                  </Media.Item>
                </Media>
              </Box>
            </Column>
          </Column.Group>

          <Column.Group style={{justifyContent:'space-around'}}>
            <Column size="5">
              <Box>
                <Media>
                  <Media.Item align="left">
                    <Image.Container size={64}>
                      <Image src={foto4} alt="foto4" />
                    </Image.Container>
                  </Media.Item>
                  <Media.Item>
                    <Content>
                      <p className="has-text-custom-royal-darker">
                        <Title size={4} className="has-text-custom-royal-darker">Gestão de Infrações de Trânsito</Title>
                        Quer ter o controle na gestão das infrações autuadas pelo seu órgão? Relatórios 
                        dinâmicos, controle da arrecadação das multas, autonomia na emissão das notificações? 
                        Quem sabe evoluir para aplicação em Divida Ativa? Somos sua melhor escolha, estamos 
                        prontos para prestar a consultoria necessária com um sistema robusto e confiável.
                      </p>
                    </Content>
                  </Media.Item>
                </Media>
              </Box>
            </Column>
            <Column size="5">
              <Box>
                <Media>
                  <Media.Item align="left">
                    <Image.Container size={64}>
                      <Image src={foto2} alt="foto2" />
                    </Image.Container>
                  </Media.Item>
                  <Media.Item>
                    <Content>
                      <p className="has-text-custom-royal-darker">
                        <Title size={4} className="has-text-custom-royal-darker">Cobrança de Repasses RENAINF</Title>
                        A multa que você autuou naquele veículo de São Paulo foi paga no licenciamento? 
                        Quer receber esse pagamento? Então venha conosco, temos a solução certa. 
                        Respeitando as normas do DENATRAN formulamos o boleto de cobrança.
                      </p>
                    </Content>
                  </Media.Item>
                </Media>
              </Box>
            </Column>
          </Column.Group>

          <Column.Group style={{justifyContent:'space-around'}}>
            <Column size="5">
              <Box>
                <Media>
                  <Media.Item align="left">
                    <Image.Container size={64}>
                      <Image src={foto5} alt="foto5" />
                    </Image.Container>
                  </Media.Item>
                  <Media.Item>
                    <Content>
                      <p className="has-text-custom-royal-darker">
                        <Title size={4} className="has-text-custom-royal-darker">Sistemas customizáveis</Title>
                        Seu órgão precisa de um gestor de protocolo? Cadastro de permissão para estacionar 
                        em vagas de idosos? Venha conversar conosco, estamos à sua disposição para desenvolver 
                        a melhor solução.
                      </p>
                    </Content>
                  </Media.Item>
                </Media>
              </Box>
            </Column>
            <Column size="5">
              <Box>
                <Media>
                  <Media.Item align="left">
                    <Image.Container size={64}>
                      <Image src={foto6} alt="foto6" />
                    </Image.Container>
                  </Media.Item>
                  <Media.Item>
                    <Content>
                      <p className="has-text-custom-royal-darker">
                        <Title size={4} className="has-text-custom-royal-darker">SysUtils, Arquivo M e FUNSETNET</Title>
                        O DENATRAN está lhe cobrando a prestação de contas das multas arrecadadas? 
                        Vamos tornar a prestação de contas transparente e eficiente, enviando mensalmente 
                        o Arquivo M dentro das normas da Portaria n. 95, de 28/07/2015.
                      </p>
                    </Content>
                  </Media.Item>
                </Media>
              </Box>
            </Column>
          </Column.Group>

          <Column.Group centered>
            <Column textAlign="centered" size={3}>
              <WhatsappButton />
            </Column>
            <Column textAlign="centered" size={3}>
              <PhoneButton />
            </Column>
          </Column.Group>

        </Container>
      </Section>
{/*
      <Section id="nossos-planos" className="nossos-planos">
        <Container>
          <Title className="has-text-custom-royal has-text-centered mb-6">
            CONHEÇA NOSSOS <span className="has-text-custom-red">PLANOS</span>
          </Title>
          <Column.Group centered>

            <Column size={3}>
              <Card style={{borderRadius:'.5rem'}}>
                <Card.Content className="has-text-custom-royal-darker" textAlign="centered" style={{paddingTop:'5rem'}}>
                  <p className="mb-2">Plano</p>
                  <Title subtitle className="has-text-weight-medium has-text-custom-royal-darker mb-2" size={2} >
                    BÁSICO
                  </Title>
                  <Tag className="px-6 mb-6 has-text-weight-medium is-italic" color="custom-red" size="medium"> -30% </Tag>
                  <p className="has-text-custom-red" style={{textDecoration:'line-through'}}>
                    R$ 599,90
                  </p>
                  <p>
                    12x de 
                    <span className="has-text-weight-medium is-size-2">
                      R$ 499,90
                    </span>
                  </p>
                  <p className="pb-5"><small>por mês, no contrato anual</small></p>
                  <Title className="has-text-weight-medium has-text-custom-royal-darker" size={6} style={{lineHeight:'1.3'}}>
                    Gestão comercial completa<br/>Acessos simultâneos ilimitados<br/>Espaço ilimitado<br/>3 usuários
                  </Title>
                </Card.Content>
              </Card>
            </Column>

            <Column size={3}>
              <Card style={{borderRadius:'.5rem'}}>
                <Card.Header className="has-background-custom-royal" style={{justifyContent:'center', borderRadius:'.5rem .5rem 0 0'}}>
                  <Content>
                      <Card.Header.Title className="has-text-weight-normal has-text-white-bis is-size-5">
                        <FontAwesomeIcon icon={faStar} className="pr-1" />
                        Recomendado
                      </Card.Header.Title>
                  </Content>
                </Card.Header>
                <Card.Content className="has-text-custom-royal-darker" textAlign="centered">
                    <p className="mb-2">Plano</p>
                    <Title subtitle className="has-text-weight-medium has-text-custom-royal-darker mb-2" size={2} >
                      PADRÃO
                    </Title>
                    <Tag className="px-6 mb-6 has-text-weight-medium is-italic" color="custom-red" size="medium"> -30% </Tag>
                    <p className="has-text-custom-red" style={{textDecoration:'line-through'}}>
                      R$ 799,90
                    </p>
                    <p>
                      12x de 
                      <span className="has-text-weight-medium is-size-2">
                        R$ 599,90
                      </span>
                    </p>
                    <p className="pb-5"><small>por mês, no contrato anual</small></p>
                    <Title className="has-text-weight-medium has-text-custom-royal-darker" size={6} style={{lineHeight:'1.3'}}>
                      Gestão comercial completa<br/>Acessos simultâneos ilimitados<br/>Espaço ilimitado<br/>5 usuários
                    </Title>
                </Card.Content>
              </Card>
            </Column>

            <Column size={3}>
              <Card style={{borderRadius:'.5rem'}}>
                <Card.Content className="has-text-custom-royal-darker" textAlign="centered" style={{paddingTop:'5rem'}}>
                  <p className="mb-2">Plano</p>
                  <Title subtitle className="has-text-weight-medium has-text-custom-royal-darker mb-2" size={2} >
                    PREMIUM
                  </Title>
                  <Tag className="px-6 mb-6 has-text-weight-medium is-italic" color="custom-red" size="medium"> -30% </Tag>
                  <p className="has-text-custom-red" style={{textDecoration:'line-through'}}>
                    R$ 1.299,90
                  </p>
                  <p>
                    12x de 
                    <span className="has-text-weight-medium is-size-2">
                      R$ 999,90
                    </span>
                  </p>
                  <p className="pb-5"><small>por mês, no contrato anual</small></p>
                  <Title className="has-text-weight-medium has-text-custom-royal-darker" size={6} style={{lineHeight:'1.3'}}>
                    Gestão comercial completa<br/>Acessos simultâneos ilimitados<br/>Espaço ilimitado<br/>Usuários Ilimitados
                  </Title>
                </Card.Content>
              </Card>
            </Column>
          </Column.Group>

          <Column.Group centered>
            <Column textAlign="centered" size={3}>
              <WhatsappButton />
            </Column>
            <Column textAlign="centered" size={3}>
              <PhoneButton />
            </Column>
          </Column.Group>

        </Container>
      </Section>
*/}
{/*
      <Section id="clientes-satisfeitos" className="clientes-satisfeitos">
        <Container>
          <Title className="has-text-custom-royal has-text-centered">
            CLIENTES <span className="has-text-custom-red">SATISFEITOS</span>
          </Title>
          <Column.Group centered>
            <Column size={9}>
              <Card>
                <Card.Content>
                  <Column.Group>
                    <Column size={5}>
                      <Image.Container size="16by9">
                        <iframe className="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/YE7VzlLtp-4" frameBorder="0" allowFullscreen title="1"></iframe>
                      </Image.Container>
                    </Column>
                    <Column className="my-3">
                      <p className="has-text-custom-royal-darker">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, eos, nulla veniam ducimus corporis est vitae iste voluptate quod sint exercitationem similique provident ipsam. Eligendi dolorum maiores possimus alias obcaecati.
                      </p>
                      <Title size={4} className="has-text-custom-royal-darker is-size-5-mobile">
                        João Saraiva - Empresa A do Brasil
                      </Title>
                    </Column>
                  </Column.Group>
                </Card.Content>
              </Card>
            </Column>
          </Column.Group>
          <Column.Group centered>
            <Column size={9}>
              <Card>
                <Card.Content>
                  <Column.Group>
                    <Column size={5}>
                      <Image.Container size="16by9">
                        <iframe className="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/YE7VzlLtp-4" frameBorder="0" allowFullscreen title="1"></iframe>
                      </Image.Container>
                    </Column>
                    <Column className="my-3">
                      <p className="has-text-custom-royal-darker">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, eos, nulla veniam ducimus corporis est vitae iste voluptate quod sint exercitationem similique provident ipsam. Eligendi dolorum maiores possimus alias obcaecati.
                      </p>
                      <Title size={4} className="has-text-custom-royal-darker is-size-5-mobile">
                        Ricardo da Silva - Empresa A do Brasil
                      </Title>
                    </Column>
                  </Column.Group>
                </Card.Content>
              </Card>
            </Column>
          </Column.Group>
        </Container>
      </Section>
*/}
      <Section id="nossa-historia" className="nossa-historia">
        <Container>
          <Title size={3} className="has-text-custom-royal has-text-centered is-size-4-mobile">
            UM POUQUINHO DA <span className="has-text-custom-red">NOSSA HISTÓRIA</span>
          </Title>
          <Card>
            <Card.Content>
              <Column.Group>
                <Column size={6}>
                  <Section className="py-3">
                    <Title subtitle size={3} className="has-text-custom-royal-darker has-text-weight-medium is-size-4-mobile">
                      Nós somos a <span className="has-text-custom-red">Temp Soluções</span>, uma
                      empresa focada em garantir
                      soluções tecnológicas para
                      órgãos de trânsito e suas
                      prestadoras de serviço
                    </Title>
                    <Title subtitle size={4} className="has-text-custom-royal-darker is-size-5-mobile">
                      Criada em 2018, nascemos com a necessidade
                      de trazer uma nova maneira tecnológica e
                      inovadora de gerenciar toda a operação necessária
                      aos órgãos para a execução dos seus serviços
                    </Title>
                  </Section>
                </Column>
                <Column size={6}>
                  <Section className="py-3">
                    <Image.Container>
                      <Image src={foto7} alt="foto7" style={{borderRadius:'.5rem'}} />
                    </Image.Container>
                  </Section>
                </Column>
              </Column.Group>
              <Section className="py-0">
                <Column.Group>
                  <Column size={4}>
                      <Column.Group gapless breakpoint="mobile">
                        <Column size={2}>
                          <Image.Container size={48} className="ml-auto">
                            <Image src={foto8} alt="foto8" />
                          </Image.Container>
                        </Column>
                        <Column size={9}>
                          <Title subtitle size={3} className="has-text-custom-royal-darker has-text-weight-medium pl-2">Missão</Title>
                        </Column>
                      </Column.Group>
                      <p className="has-text-custom-royal-darker is-size-5">
                        Desenvolver e implementar sistemas
                        informatizados, buscando agregar
                        valor para os negócios de nossos clientes.
                      </p>
                  </Column>
                  <Column size={4}>
                    <Column.Group gapless breakpoint="mobile">
                      <Column size={2}>
                        <Image.Container size={48} className="ml-auto">
                          <Image src={foto9} alt="foto9" />
                        </Image.Container>
                      </Column>
                      <Column size={9}>
                        <Title subtitle size={3} className="has-text-custom-royal-darker has-text-weight-medium pl-2">Visão</Title>
                      </Column>
                    </Column.Group>
                    <p className="has-text-custom-royal-darker is-size-5">
                      Desenvolver e implementar sistemas
                      informatizados, buscando agregar
                      valor para os negócios de nossos clientes.
                    </p>
                  </Column>
                  <Column size={4}>
                    <Column.Group gapless breakpoint="mobile">
                      <Column size={2}>
                        <Image.Container size={48} className="ml-auto">
                          <Image src={foto10} alt="foto10" />
                        </Image.Container>
                      </Column>
                      <Column size={9}>
                        <Title subtitle size={3} className="has-text-custom-royal-darker has-text-weight-medium pl-2">Valores</Title>
                      </Column>
                    </Column.Group>
                    <p className="has-text-custom-royal-darker is-size-5">
                      Desenvolver e implementar sistemas
                      informatizados, buscando agregar
                      valor para os negócios de nossos clientes.
                    </p>
                  </Column>
                </Column.Group>
              </Section>
            </Card.Content>
          </Card>
        </Container>
      </Section>

      <Section id="atendimento" className="atendimento">
        <Container>
          <Column.Group>
            <Column size={9}>
              <Title size={2} className="has-text-custom-royal-darker">
                ATENDIMENTO<br/><span className="has-text-custom-red">SEMPRE DISPONÍVEL</span>
              </Title>
              <Title size={4} className="has-text-custom-royal-darker has-text-weight-medium mt-0">
                Atendimento por telefone:
              </Title>
              <Title subtitle size={4} className="has-text-custom-royal-darker" style={{marginTop:'-1.7rem'}}>
                De segunda à sexta feira das 8h às 18h
              </Title>
              <Title size={4} className="has-text-custom-royal-darker has-text-weight-medium mt-0">
                Atendimento via whatsapp:
              </Title>
              <Title subtitle size={4} className="has-text-custom-royal-darker" style={{marginTop:'-1.7rem'}}>
                De segunda à sexta feira das 8h às 20h
              </Title>
            </Column>
{/*
            <Column className="px-4" size={3} textAlign="centered">
              <PhoneButton size="large" />
            </Column>
*/}
            <Column className="px-4" size={3} textAlign="centered">
              <WhatsappButton size="large" />
            </Column>
          </Column.Group>
        </Container>
      </Section>

      <Footer className="todos-direitos-reservados has-background-custom-royal-darker">
        <Container>
          <Column.Group>
            <Column size={2}>
              <img src={logoTempWhite} alt="" width="135" />
            </Column>
            <Column>
              <p className="pb-5">Copyright © 2021 TempSolucoes.com.br</p>
              <p>
                TEMP MOVILIDAD SOLUCÕES E CONSULTORIA<br/>
                Rua Nunes Valente, 980 - Aldeota - Fortaleza-CE
              </p>
            </Column>
            <Column textAlign="right">
              <a href="#home" className="back-to-top">
                <FontAwesomeIcon icon={faChevronUp}/>
              </a>
            </Column>
          </Column.Group>
        </Container>
      </Footer>
    </>
  )
}

export default App;
