import React, { useState, useEffect } from 'react';
import { Card, Image, Title } from "rbx";
import foto12 from '../assets/images/foto12.png'
import horizontalLine from '../assets/images/horizontal-line.png'
import useWindowSize from './useWindowSize';


export default function WhatsappButton({size="small"}) {
  const [urlWhatsapp, setUrlWhatsapp] = useState("");
  const windowSize = useWindowSize();

  useEffect(() => {
    setUrlWhatsapp(`https://${windowSize.width < 1024 ? 'api' : 'web'}.whatsapp.com/send?phone=5585997408082`)
  }, [windowSize.width])

  if (size==="small") {
    return (
      <a 
        className="button is-rounded is-fullwidth has-background-custom-green has-text-white"
        href={urlWhatsapp}
        target="_blank"
        rel="noreferrer"
      >
        <Image.Container size={24} className="mr-2">
          <Image src={foto12} alt="foto12" />
        </Image.Container>
        <strong>FALAR COM CONSULTOR</strong>
      </a>
    )
  } else if (size==="large") {
    return (
      <a 
        href={urlWhatsapp}
        target="_blank"
        rel="noreferrer"
      >
        <Card>
          <Card.Content className="has-background-custom-green pt-6 pb-0">
            <Image.Container size={96} className="mx-auto mb-6">
              <Image src={foto12} alt="foto12"/>
            </Image.Container>
            <Title subtitle size={4} className="has-text-white has-text-weight-medium">
              Fale via whats
            </Title>
            <Title className="has-text-white mb-0">
              (85) 9 9740-8082
            </Title>
            <Image.Container size={48} className="mx-auto">
              <Image src={horizontalLine} alt="horizontalLine" style={{filter: 'invert(100%)'}} />
            </Image.Container>
          </Card.Content>
        </Card>
      </a>
    )
  } else {
    return (<span>size invalid!</span>)
  }
}
